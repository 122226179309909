
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { getStaticProps as __Next_Translate_old_getStaticProps__1918dbca510__ } from "@modules/Pages/resourceCategories/homepage";
import type { NextPage } from "next";
import ResourceView from "@components/ResourceView/ResourceView";
import { IPageData } from "@components/ResourceView/types";
import Meta from "@components/seo/Meta";
import { IPage } from "../features/cms/types";
interface ICustomPageProps {
    page: IPage;
    pageData: IPageData;
    url?: string;
}
const CustomPage: NextPage<ICustomPageProps> = ({ page, pageData, url }) => {
    const meta = {
        title: page.title,
        description: page.metaDescription,
        keywords: page.metaKeywords,
    };
    return (<>
      <Meta {...meta}/>
      <ResourceView key={url} resource={page} pageData={pageData}/>
    </>);
};
export {} from "@modules/Pages/resourceCategories/homepage";
export default CustomPage;

    async function __Next_Translate__getStaticProps__1918dbca510__(ctx) {
      const res = await __Next_Translate_old_getStaticProps__1918dbca510__(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/homepage',
            loaderName: 'getStaticProps',
            
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1918dbca510__ as getStaticProps }
  